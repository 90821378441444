<template>
  <div class="woke_Amend">
    <div class="main_header">
      <div class="left">
        <i @click="gotoback" class="el-icon-arrow-left"></i>
        <div class="title">
          <p class="p_title">
            <!-- {{ this.p_title ? this.p_title : "未命名标题" }} -->
            {{ this.textInfoById.title }}
          </p>
          <p class="p_date">
            <!-- 更新于&emsp;{{ this.p_date ? this.p_date : "刚刚" }} -->
            {{ this.textTime }}
          </p>
        </div>
      </div>
    </div>
    <div class="main_content">
      <div class="content_title content">
        <!-- <p class="p_title">{{ this.textInfoById.title }}</p> -->
        <input
          type="text"
          class="inp_title"
          v-model="textInfoById.title"
          maxlength="10"
          v-on:input="onChange"
        />
      </div>
      <div
        class="content_inp content"
        contenteditable="true"
        v-on:input="change"
      >
        <div class="textarea" ref="div_inp">
          {{ this.textInfoById.text }}
        </div>
      </div>
      <div class="content_save content">
        <div class="save_btn">
          <p class="save_num">正文字数：{{ this.textNum }}</p>
          <div class="save_sure" @click="save">保存修改</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetUserWorkById, UpdateUserWork } from "../request/api";
import moment from "moment";
export default {
  name: "App",
  props: ["id"],
  data() {
    return {
      userInfo: {},
      textId: null,
      textInfoById: {},
      textNum: null,
      textTime: "",
    };
  },
  methods: {
    gotoback() {
      Object.keys(this.textInfoById).forEach(
        (key) => (this.textInfoById[key] = "")
      );
      this.$router.push("/livedemo/livemywoke");
    },
    change() {
      // console.log(this.$refs.div_inp.innerText);
      this.textNum = this.$refs.div_inp.innerText.length;
      if (this.$refs.div_inp.innerHTML == "<br>") {
        this.$refs.div_inp.innerHTML = ''
        this.textNum = this.$refs.div_inp.innerText.length;
      }
    },

    KeyDown(e) {
      // console.log(e.keyCode);
      if (e.keyCode == "13") {
        e.preventDefault();
      } else if (e.keyCode == "8") {
        // console.log(this.textNum);
        if (this.textNum < 1) {
          e.preventDefault();
        }
      }
    },

    onChange() {
      // console.log(this.textInfoById.title);
    },
    async save() {
      this.$confirm("此操作将永久修改文案", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.textInfoById.text = this.$refs.div_inp.innerText;
          // console.log(this.textInfoById);
          // console.log("修改成功", this.textInfoById);
          await UpdateUserWork({ ...this.textInfoById }).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "修改成功!",
              });
              // console.log(res);
              let list = this.$store.state.writeList;
              // console.log(list);
              list.map((item, index) => {
                if (item.id == this.textInfoById.id) {
                  item.text = this.textInfoById.text;
                  item.time = moment().format("YYYY-MM-DD HH:mm");
                  // console.log(item.time);
                  list.splice(index, 1);
                  list.unshift(item);
                }
              });
              this.$store.commit("writeList", [...list]);
            } else {
              this.$message({
                type: "warning",
                message: res.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
  created() {
    this.textId = this.$route.query.id;
    this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    // console.log(JSON.parse(sessionStorage.getItem("writeList")));
    // JSON.parse(sessionStorage.getItem("writeList")
    // this.$store.commit("writeList", [
    //   ...JSON.parse(sessionStorage.getItem("writeList")),
    // ]);

    // console.log(this.$route.query);
    GetUserWorkById(this.textId).then((res) => {
      // console.log(res);
      if (res.code == 200) {
        this.textInfoById = { ...res.result };
        this.textNum = this.textInfoById.text.length;
        this.textTime = moment(new Date(res.result.updateTime)).format(
          "YYYY-MM-DD HH:mm"
        );

        // console.log(this.textInfoById);
        // console.log(JSON.parse(this.textInfoById));

        // if (this.textInfoById) {
        // } else {
        // }

        // console.log(this.textInfoById);
      } else {
        this.$message.error(res.message);
        this.$router.push("/livedemo/livemywoke");
      }
    });
  },
  mounted() {
    window.addEventListener("keydown", this.KeyDown, true);
  },
  watch: {
    $route: {
      handler(val) {
        if (val.query.id) {
          // console.log(val.query.id);
          GetUserWorkById(val.query.id).then((res) => {
            // console.log(res);
            if (res.code == 200) {
              this.textInfoById = { ...res.result };
              this.textNum = this.textInfoById.text.length;
              // console.log(this.textInfoById);
              let date = new Date(this.textInfoById.updateTime);
              var Y = date.getFullYear() + "-";
              var M =
                (date.getMonth() + 1 < 10
                  ? "0" + (date.getMonth() + 1)
                  : date.getMonth() + 1) + "-";
              var D =
                date.getDate() < 10
                  ? "0" + date.getDate() + " "
                  : date.getDate() + " ";
              var H = date.getHours() + ":";
              var M2 =
                date.getMinutes() < 10
                  ? "0" + date.getMinutes()
                  : date.getMinutes();
              this.textTime = Y + M + D + H + M2;
            }
          });
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang='scss' scoped>
.woke_Amend {
  width: 83.75rem;
  height: 53.75rem;
  margin: 0 auto;
  .main_header {
    width: 100%;
    height: 5.625rem;
    background-color: transparent;
    display: flex;

    .left {
      display: flex;

      i {
        height: 100%;
        line-height: 5.625rem;
        font-size: 2rem;
        font-weight: 900;
      }

      .title {
        overflow: hidden;
        margin-left: 1rem;

        .p_title {
          font-size: 1.125rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #191f33;
          line-height: 1.5625rem;
          margin-top: 1.25rem;
          text-align: left;
        }
        .inp_title {
        }

        .p_date {
          margin-top: 0.25rem;
          font-size: 0.875rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #7a7b80;
          line-height: 1.25rem;
          text-align: left;
        }
      }
    }
  }

  .main_content {
    width: 82.5rem;
    height: 48.125rem;
    background: #ffffff;
    box-shadow: 0.25rem 0 0.625rem 0 #f1f3f9;
    border-radius: 1rem;
    border: 0.0625rem solid #ebedf2;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .content {
      width: 100%;
    }
    .content_title {
      width: 100%;
      height: 5rem;
      .p_title {
        font-size: 1.5rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #191f33;
        line-height: 5rem;
        text-align: left;
        padding-left: 2.125rem;
      }
      .inp_title {
        font-size: 1.5rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #191f33;
        line-height: 5rem;
        float: left;
        padding-left: 2.125rem;
        outline: none;
      }
    }
    .content_inp {
      flex: 1;
      position: relative;
      overflow: hidden;
      .textarea {
        width: 100%;
        height: 100%;
        padding-right: 2rem;
        //   background-color: springgreen;
        flex: 1;
        font-size: 0.875rem;
        text-align: left;
        // text-indent: 1.5rem;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: scroll;
        line-height: 1.5rem;
        position: absolute;
        right: -2.1rem;
      }
    }
    .content_save {
      height: 5.3125rem;
      background: #ffffff;
      box-shadow: 0 -0.25rem 0.625rem 0 #f1f3f9;
      border-radius: 0 0 1rem 1rem;
      .save_btn {
        height: 100%;
        // width: 20.625rem;
        float: right;
        // background-color: red;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding-right: 2rem;
        .save_num {
          font-size: 1rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #a4abc0;
          line-height: 5.3125rem;
          margin-right: 2.3125rem;
        }
        .save_sure {
          width: 11.25rem;
          height: 3.125rem;
          background: #4673ff;
          border-radius: 0.5rem;
          font-size: 1.125rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          margin-top: 1.0625rem;
          line-height: 3.125rem;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
